import Swiper, { EffectFade, Autoplay } from 'swiper';
import imagesLoaded from 'imagesloaded';

window.Front = function () {
  this.init = function () {
    imagesLoaded('.swiper-container', () => {
      Swiper.use([EffectFade, Autoplay]);

      new Swiper('.swiper-container', {
        speed: 700,
        slidesPerView: 1,
        effect: 'fade',
        loop: true,
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: true
        }
      });
    });
  }
};

new Front().init();
